import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormControlField from './FormControlField';
import Checkbox from './Checkbox';
import Button from './Button';
import TicketDTO, { CreateTicketDTO } from '../models/ticket';
import {
  RootState,
  useCreateTicketMutation,
  useUpdateTicketMutation,
} from '../store';
import Accordion from './Accordion';

interface Props {
  ticket?: TicketDTO;
  onSuccess: () => void;
}

interface FormData {
  isEnabled: boolean;
  name: string;
  quantity: number;
  price: number;
  entriesGenerated: number;
  maxPerOrder: number;
  hasOpenPrice: boolean;
  minOpenPrice: number | null | undefined;
}

const validationSchema = yup.object().shape({
  isEnabled: yup.boolean().required(),
  name: yup.string().required('Debes ingresar un nombre'),
  quantity: yup
    .number()
    .required('Debes ingresar una cantidad de entradas')
    .typeError('Debes ingresar un número'),
  price: yup
    .number()
    .required('Debes ingresar un precio')
    .typeError('Debes ingresar un número'),
  entriesGenerated: yup
    .number()
    .required('Debes ingresar un número')
    .typeError('Debes ingresar un número'),
  maxPerOrder: yup
    .number()
    .required('Debes ingresar un número')
    .typeError('Debes ingresar un número'),
  hasOpenPrice: yup.boolean().required(),
  minOpenPrice: yup
    .number()
    .nullable()
    .optional()
    .when('hasOpenPrice', {
      is: true,
      then: (schema) => schema
        .required('Debes ingresar un precio abierto mínimo')
        .typeError('Debes ingresar un número'),
      otherwise: (schema) => schema.optional().notRequired().nullable(),
    }),
});

const CreateTicket: React.FC<Props> = ({ ticket, onSuccess }) => {
  const {
    register, handleSubmit, formState, watch,
  } = useForm<FormData>({
    defaultValues: {
      isEnabled: ticket ? ticket.active : true,
      name: ticket?.name,
      quantity: ticket?.total_amount,
      price: ticket?.price,
      entriesGenerated: ticket?.items_create || 1,
      maxPerOrder: ticket?.max_per_order,
      hasOpenPrice: ticket?.has_open_price,
      minOpenPrice: ticket?.min_open_price ?? 0,
    },
    resolver: yupResolver(validationSchema),
  });

  const hasOpenPrice = watch('hasOpenPrice');
  const tickets = useSelector((state: RootState) => state.tickets.tickets);
  const { event_id: eventId, business_id: businessId } = useParams();

  const [createTicket, resCreateTicket] = useCreateTicketMutation();
  const [updateTicket, resUpdateTicket] = useUpdateTicketMutation();

  useEffect(() => {
    if (resCreateTicket.isSuccess || resUpdateTicket.isSuccess) {
      onSuccess();
    }
  }, [resCreateTicket.isSuccess, resUpdateTicket.isSuccess, onSuccess]);

  if (!tickets || !eventId || !businessId) {
    throw new Error('tickets is null or eventID or businessId is null');
  }

  const parseFormToDto = (data: FormData): CreateTicketDTO => ({
    name: data.name,
    price: data.price,
    total_amount: data.quantity,
    active: data.isEnabled,
    items_create: data.entriesGenerated,
    max_per_order: data.maxPerOrder,
    has_open_price: data.hasOpenPrice,
    min_open_price: data.hasOpenPrice ? data.minOpenPrice : null,
  });

  const onSubmit = async (data: FormData) => {
    const parsedTicket = parseFormToDto(data);
    if (ticket) {
      updateTicket({
        ticket: { ...parsedTicket, id: ticket.id },
        eventId: +eventId,
        businessId: +businessId,
      });
    } else {
      createTicket({
        ticket: parsedTicket,
        eventId: +eventId,
        businessId: +businessId,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
        <div className="mb-4">
          <Checkbox
            label="Habilitada"
            labelClassName="text-gray-900"
            {...register('isEnabled')}
          ></Checkbox>
        </div>

        <FormControlField
          {...register('name', { required: 'This field is required' })}
          id="name"
          label="Nombre"
          labelAbove={true}
          errMsg={formState.errors.name?.message}
        />

        <div className="grid grid-cols-2 gap-4 mb-4">
          <FormControlField
            {...register('quantity', { required: true })}
            id="quantity"
            type="number"
            label="Cantidad"
            labelAbove={true}
            errMsg={formState.errors.quantity?.message}
          />
          <FormControlField
            {...register('price', { required: true })}
            id="price"
            type="number"
            label="Precio"
            labelAbove={true}
            errMsg={formState.errors.price?.message}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <FormControlField
            {...register('maxPerOrder', { required: true })}
            id="maxPerOrder"
            type="number"
            label="Cantidad máxima por orden"
            labelAbove={true}
            errMsg={formState.errors.maxPerOrder?.message}
          />
        </div>
        <Accordion title="Ajustes avanzados">
          <div>
          <FormControlField
                {...register('entriesGenerated', { required: true })}
                id="entriesGenerated"
                type="number"
                label="Entradas que genera"
                labelAbove={true}
                errMsg={formState.errors.entriesGenerated?.message}
              />
            <div className="mb-2">
              <Checkbox
                label="Tiene precio abierto"
                labelClassName="text-gray-900"
                {...register('hasOpenPrice')}
              ></Checkbox>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              {hasOpenPrice && (
                <FormControlField
                  {...register('minOpenPrice')}
                  id="minOpenPrice"
                  type="number"
                  label="Precio abierto mínimo"
                  labelAbove={true}
                  errMsg={formState.errors.minOpenPrice?.message}
                />
              )}
            </div>
          </div>
        </Accordion>
        <div className="flex flex-col justify-between">
          <Button
            type="submit"
            primary
            className="mb-2 mt-5"
            loading={resCreateTicket?.isLoading || resUpdateTicket.isLoading}
          >
            Guardar cambios
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateTicket;
