import SortableTable from './SortableTable';
import InvitationDTO from '../models/invitation';

interface Props {
  invitations: InvitationDTO[];
}

const InvitationsTable: React.FC<Props> = ({ invitations }) => {
  const config = [
    {
      label: 'Correo',
      render: (invitation: InvitationDTO) => invitation.email,
    },
    {
      label: 'Permisos',
      render: (invitation: InvitationDTO) => invitation.permissions,
    },
  ];

  const keyFn = (invitation: InvitationDTO) => `${invitation.id}`;

  return (
    <div>
      <SortableTable data={invitations} config={config} keyFn={keyFn} pagination={false} search={false}/>
    </div>
  );
};

export default InvitationsTable;
