import { createApi } from '@reduxjs/toolkit/query/react';
import EventDTO from '../../models/event';
import { Response } from '../../models/shared';
import { AgentDTO } from '../../models/agent';
import baseQueryWithReauth from './base';
import { QuestionDTO } from '../../models/poll';

const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['events'],
  endpoints: (build) => ({
    getEventById: build.query<EventDTO | null, { eventId: number }>({
      providesTags: (result, error, { eventId }) => [{ type: 'events', eventId }],
      query: ({ eventId }) => ({
        url: `/events/${eventId}`,
        method: 'GET',
      }),
      transformResponse: (response: Response<EventDTO>): (EventDTO | null) => response.data || null,
    }),
    getAllEvents: build.query<EventDTO[] | null, { query?: Partial<EventDTO> }>({
      providesTags: () => [{ type: 'events' }],
      query: ({ query }) => ({
        url: '/events',
        params: query,
        method: 'GET',
      }),
      transformResponse: (response: Response<EventDTO[]>): (EventDTO[] | null) => response.data || null,
    }),
    getAllBusinessEvents: build.query<Response<EventDTO[]>, number>({
      providesTags: () => [{ type: 'events' }],
      query: (business_id) => ({
        url: `/businesses/${business_id}/events`,
        method: 'GET',
      }),
    }),
    getBusinessEvents: build.query<Response<EventDTO[]>, { businessId: number, onlyActive?: boolean }>({
      providesTags: () => [{ type: 'events' }],
      query: ({ businessId, onlyActive }) => ({
        url: `/businesses/${businessId}/events`,
        method: 'GET',
        params: {
          active: onlyActive,
        },
      }),
    }),
    getCurrentAgent: build.query<AgentDTO | null, { businessId: number, eventId: number }>({
      providesTags: () => [{ type: 'events' }],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/agents`,
        method: 'GET',
        params: {
          now: 'true',
        },
      }),
      transformResponse: (response: Response<AgentDTO>): (AgentDTO | null) => response.data || null,
    }),
    createEvent: build.mutation<Response<EventDTO>, { event: Partial<EventDTO>, businessId: number }>({
      query: ({
        event, businessId,
      }) => ({
        url: `/businesses/${businessId}/events/`,
        method: 'POST',
        body: event,
      }),
      invalidatesTags: ['events'],
    }),
    updateEvent: build.mutation<Response<EventDTO>, { event: Partial<EventDTO>, businessId: number }>({
      query: ({
        event, businessId,
      }) => ({
        url: `/businesses/${businessId}/events/`,
        method: 'PUT',
        body: event,
      }),
      invalidatesTags: ['events'],
    }),
    getEventQuestions: build.query<QuestionDTO[] | null, { eventId: number }>({
      providesTags: () => [{ type: 'events' }],
      query: ({ eventId }) => ({
        url: `/events/${eventId}/polls/questions`,
        method: 'GET',
      }),
      transformResponse: (response: Response<QuestionDTO[]>): (QuestionDTO[] | null) => response.data || null,
    }),
  }),
});

export const {
  useGetEventByIdQuery, useGetAllBusinessEventsQuery, useGetBusinessEventsQuery, useGetCurrentAgentQuery, useGetAllEventsQuery, useCreateEventMutation, useUpdateEventMutation, useGetEventQuestionsQuery,
} = eventsApi;
export { eventsApi };
