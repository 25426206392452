interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
}

const TextTitleCard: React.FC<Props> = ({ title, children }) => (
  <div className="rounded-lg p-4 bg-white shadow w-full">
    {title && <h2 className="text-lg font-medium mb-2">{title}</h2>}
    {title && <hr className="mb-3" />}
    <div className="flex flex-col text-md">{children}</div>
  </div>
);

export default TextTitleCard;
