import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import getErrorResponse from './getErrorResponse';
import parseErrors from './parseErrors';

const errorResponseToString = (response: FetchBaseQueryError | SerializedError) => {
  const errorData = getErrorResponse(response);
  return parseErrors(errorData.error);
};

export default errorResponseToString;
