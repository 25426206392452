import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import SelectTicketsTable, {
  TicketSelection,
} from '../components/SelectTicketsTable';
import {
  RootState, setTicketsSelected, useGetAllTicketsQuery, useReserveTicketsMutation,
} from '../store';
import Button from '../components/Button';
import TicketDTO, { TicketReservation } from '../models/ticket';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

const SelectTicketsPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();
  const { event_id: eventId } = useParams();
  const location = useLocation();
  const isBackoffice = location.pathname.includes('admin');
  const event = useSelector((state: RootState) => state.events.selectedEvent);
  const { data: eventTickets, isLoading: ticketsLoading } = useGetAllTicketsQuery({ businessId: event?.business_id || 0, eventId: +(eventId || 0) }, { skip: !event || !eventId });
  const [tickets, setTickets] = useState<TicketDTO[]>([]);
  const [warning, setWarning] = useState(false);
  const [ticketSelection, setTicketsSelection] = useState<TicketSelection[]>(
    [],
  );
  const [reserveTickets, resultsReserve] = useReserveTicketsMutation();
  const [ticketKey, setTicketKey] = useState(0); // used to reset counter on tickets change

  if (!eventId) {
    throw new Error('invalid url');
  }

  useEffect(() => {
    setTickets(eventTickets?.filter((t) => (isBackoffice ? t : t.active)) || []);
    setTicketKey((prevKey) => prevKey + 1);
  }, [eventTickets]);

  useEffect(() => {
    localStorage.removeItem('formItemsData');
  }, []);

  useEffect(() => {
    if (ticketSelection.length > 0) {
      setWarning(false);
    }
  }, [ticketSelection]);

  if (!event) {
    navigate('../../');
    return <div>no event</div>;
  }

  const handleTicketsChange = (updatedSelection: TicketSelection[]) => {
    setTicketsSelection(updatedSelection);
  };

  const handleClickNext = async () => {
    if (ticketSelection.length === 0) {
      return setWarning(true);
    }
    const reservations: TicketReservation[] = ticketSelection.map((ts) => ({ ticket_id: ts.ticket.id, amount: ts.quantity }));
    const result = await reserveTickets({ businessId: event.business_id, eventId: event.id, reservations });
    let errorMsg = 'Ocurrió un error';
    if ('data' in result) {
      if (result.data.data?.valid) {
        dispatch(setTicketsSelected(ticketSelection));
        return navigate('../entry-data');
      }
      if (result.data.data?.valid === false) {
        errorMsg = 'Las entradas seleccionadas se agotaron';
      }
    } else {
      console.error('error reserving tickets', result);
    }
    toast.error(errorMsg, {
      duration: 7000,
      position: 'bottom-center',
    });
    return '';
  };

  return (
    <div>
      <div className="card border border-gray-200">
        <h1 className="text-lg p-1">Tickets</h1>
        {ticketsLoading && <OrdersTableSkeleton/>}
        {!ticketsLoading && <SelectTicketsTable
          tickets={tickets} // pendiente resetear el contador cuando se agoten
          onTicketsChange={(updatedTicketsSelection) => handleTicketsChange(updatedTicketsSelection)}
          key={ticketKey}
        />}
      </div>
      {warning && (
        <div className="text-red-500 text-center mt-2">
          Debes seleccionar al menos una entrada
        </div>
      )}
      <div className="flex flex-col justify-center mt-10">
        <Button
          className="mb-2 h-12"
          primary
          onClick={handleClickNext}
          loading={resultsReserve.isLoading}
        >
          Siguiente
        </Button>
        <Button transparent onClick={() => navigate('../../')}>
          Volver
        </Button>
      </div>
    </div>
  );
};

export default SelectTicketsPage;
