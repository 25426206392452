import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useUpdateOrderItemMutation, useUpdateOrderMutation } from '../store';
import FormControlField from './FormControlField';
import Button from './Button';
import OrderItem from '../types/OrderItem';

interface Props {
  order: OrderItem;
  onSuccess: () => void;
}

interface EditOrderData {
  last_name: string;
  name: string;
  person_id: string;
  email: string;
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Debes ingresar un nombre'),
  last_name: yup.string().required('Debes ingresar un apellido'),
  person_id: yup.string().required('Debes ingresar un DNI'),
  email: yup.string().required('Debes ingresar un correo'),
});

const EditOrderForm: React.FC<Props> = ({ order, onSuccess }) => {
  const [updateOrderItem, results] = useUpdateOrderItemMutation();
  const [updateOrder, resultsOrder] = useUpdateOrderMutation();

  const { event_id: eventId, business_id: businessId } = useParams();
  if (!eventId || !+eventId || !businessId || !+businessId) {
    throw new Error('no event_id or business_id');
  }

  const form = useForm<EditOrderData>({
    defaultValues: {
      name: order.first_name,
      last_name: order.last_name,
      person_id: order.person_id,
      email: order.email,
    },
    resolver: yupResolver<EditOrderData>(validationSchema),
  });

  const { register, handleSubmit, formState } = form;

  const { isDirty } = formState;

  const onSubmit = async (data: EditOrderData) => {
    const { email, ...itemData } = data;
    await updateOrderItem({
      businessId: +businessId,
      eventId: +eventId,
      orderId: order.id,
      item: itemData,
      itemId: order.item_id,
    });
    if (formState.dirtyFields.email) {
      await updateOrder({
        order: { email },
        businessId: +businessId,
        orderId: order.id,
        eventId: +eventId,
      });
    }
    onSuccess();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControlField
          label="Nombre"
          id="name"
          {...register('name', {
            required: true,
          })}
          errMsg={formState.errors.name?.message}
        />
        <FormControlField
          label="Apellido"
          id="last_name"
          {...register('last_name', {
            required: true,
          })}
          errMsg={formState.errors.last_name?.message}
        />
        <FormControlField
          label="DNI"
          id="person_id"
          {...register('person_id', {
            required: true,
          })}
          errMsg={formState.errors.person_id?.message}
        />
        <FormControlField
          label="Correo"
          id="email"
          {...register('email', {
            required: true,
          })}
          errMsg={formState.errors.last_name?.message}
        />
        <div className="flex justify-center mt-10">
          <Button
            primary
            loading={results.isLoading || resultsOrder.isLoading}
            disabled={!isDirty}
            className="w-full"
            type="submit"
          >
            Guardar cambios
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditOrderForm;
