import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OrderData } from '../apis/orders';
import { OrderItemData } from '../../models/order';
import { PollData } from '../../models/poll';

interface OrdersState {
  createdOrder: OrderData | null;
  orderItemData: OrderItemData[];
  pollData: PollData[];
}

const initialState: OrdersState = {
  createdOrder: null,
  orderItemData: [],
  pollData: [],
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setCreatedOrder: (state, action: PayloadAction<OrderData>) => ({ ...state, createdOrderOrder: action.payload }),
    setOrderItemData: (state, action: PayloadAction<OrderItemData[]>) => ({ ...state, orderItemData: action.payload }),
    setPollData: (state, action: PayloadAction<PollData[]>) => ({ ...state, pollData: action.payload }),
  },
});

export const { setCreatedOrder, setOrderItemData, setPollData } = ordersSlice.actions;
export const ordersReducer = ordersSlice.reducer;
