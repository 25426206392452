/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useForm, Controller, FieldError } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { OrderItemData } from '../models/order';
import Button from '../components/Button';
import FormCardFrame from '../components/FormCardFrame';
import SectionTitle from '../components/SectionTitle';
import { PollData, QuestionDTO, QuestionResponse } from '../models/poll';
import { setPollData } from '../store';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

interface QuestionsFormProps {
  questions: QuestionDTO[];
  orderItems: OrderItemData[];
}

type SchemaType = {
  [key: string]: yup.StringSchema;
};

const validationSchema = (
  questions: QuestionDTO[],
  orderItems: OrderItemData[],
) => {
  const schema: SchemaType = {};

  orderItems.forEach((item, index) => {
    questions.forEach((question) => {
      const key = `${index}_${question.id}`;
      if (question.mandatory) {
        schema[key] = yup.string().required(`${question.value} es obligatorio`);
      } else {
        schema[key] = yup.string();
      }
    });
  });

  return yup.object().shape(schema);
};

const getErrorMessage = (error: FieldError | undefined) => error?.message;

const QuestionsForm: React.FC<QuestionsFormProps> = ({
  questions,
  orderItems,
}) => {
  const navigate = useNavigateWithQuery();
  const dispatch = useDispatch();
  const [sortedQuestions, setSortedQuestions] = useState<QuestionDTO[]>([]);

  useEffect(() => {
    if (questions) {
      setSortedQuestions([...questions].sort((a, b) => a.order - b.order));
    }
  }, [questions]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema(questions, orderItems)),
    mode: 'onChange',
  });

  const onSubmit = (data: any) => {
    const mappedData: PollData[] = orderItems.map((item, index) => {
      const responses: QuestionResponse[] = questions.map((q) => ({
        question_id: q.id,
        question_text: q.value,
        value: data[`${index}_${q.id}`],
      }));
      return {
        name: item.name,
        last_name: item.last_name,
        person_id: item.person_id,
        question_responses: responses,
      };
    });
    dispatch(setPollData(mappedData));
    navigate('../checkout?customized=true');
  };

  const onGoBack = () => {
    localStorage.removeItem('formItemsData');
    navigate('../entry-data');
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
        <FormCardFrame>
          {orderItems.map((item, index) => (
            <div key={index} className="mb-7">
              <SectionTitle
                index={index}
                customizeData={true}
                item={item.ticket}
              />
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">
                  Nombre Completo
                </label>
                <input
                  className="w-full px-3 py-2 border rounded shadow focus:outline-none focus:shadow-outline"
                  type="text"
                  value={`${item.name} ${item.last_name}`}
                  disabled
                />
              </div>
              {sortedQuestions.map((question, qIndex) => (
                <div key={qIndex} className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">
                    {question.value}
                  </label>
                  {question.is_open ? (
                    <Controller
                      name={`${index}_${question.id}`}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="w-full px-3 py-2 border rounded shadow focus:outline-none focus:shadow-outline"
                          type={question.value === 'Edad' ? 'number' : 'text'}
                          placeholder={question.value}
                          inputMode={question.value === 'Edad' ? 'numeric' : 'text'}
                          pattern={question.value === 'Edad' ? '[0-9]*' : ''}
                          min={question.value === 'Edad' ? 1 : undefined}
                          max={question.value === 'Edad' ? 99 : undefined}
                        />
                      )}
                    />
                  ) : (
                    <Controller
                      name={`${index}_${question.id}`}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <select
                          {...field}
                          className="block w-full bg-white border border-gray-300 text-gray-700 py-2 px-3 rounded shadow leading-tight focus:outline-none focus:shadow-outline focus:bg-white h-10"
                        >
                          <option value="">Seleccionar una opción</option>
                          {question.options?.map((option, idx) => (
                            <option key={idx} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                  )}
                  {errors[`${index}_${question.id}`] && (
                    <p className="text-sm text-red-500 white-text-shadow">
                      {getErrorMessage(
                        errors[`${index}_${question.id}`] as FieldError,
                      )}
                    </p>
                  )}
                </div>
              ))}
            </div>
          ))}
        </FormCardFrame>
        <div className="flex flex-col justify-center mt-10">
          <Button type="submit" className="mb-2 h-12" primary>
            Siguiente
          </Button>
          <Button type="button" transparent onClick={onGoBack}>
            Volver
          </Button>
        </div>
      </form>
    </div>
  );
};

export default QuestionsForm;
