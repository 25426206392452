import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import Button from './Button';

interface Props {
  id: number;
  title: string;
  info: string;
  buttonLabel: string;
  redirectPath: string;
  onButtonClick?: (id: number) => void;
}

const EventMenuOption: React.FC<Props> = ({
  id, title, info, buttonLabel, redirectPath, onButtonClick,
}) => {
  const navigate = useNavigateWithQuery();

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick(id);
    }
    navigate(redirectPath);
  };

  return (
    <div className="card flex flex-col p-4 bg-white mb-4"> {/* Ensured flex direction is column */}
      <h1 className="text-lg font-medium mb-1">{title}</h1>
      <p className="text-center mb-4">{info}</p> {/* Corrected text alignment class */}
      <div className="flex justify-center items-center mt-5 mb-2">
        <Button onClick={handleButtonClick} primary>{buttonLabel}</Button>
      </div>
    </div>
  );
};

export default EventMenuOption;
