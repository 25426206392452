import {
  createApi,
} from '@reduxjs/toolkit/query/react';
import { Response } from '../../models/shared';
import UserDTO, { AuthUserData } from '../../models/user';
import { BusinessDTO } from '../../models/business';
import baseQueryWithReauth from './base';

interface LogInData {
  email: string,
  password: string,
}

interface SignUpData {
  email: string;
  password: string;
  username: string;
}

export interface LogInResponse {
  token: string;
  user_data: AuthUserData
}

export interface CustomError {
  data:{
    error: string,
    msg: string,
  },
  status: number
}

export interface GoogleLoginDTO {
  email: string;
  display_name: string;
  firebase_id: string
}

const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['users'],
  endpoints: (build) => ({
    logIn: build.mutation<Response<LogInResponse>, LogInData>({
      query: (loginData) => ({
        url: '/users/login',
        method: 'POST',
        body: loginData,
      }),
    }),
    createUser: build.mutation<UserDTO | null, SignUpData>({
      query: (signUpData) => ({
        url: '/users',
        method: 'POST',
        body: signUpData,
      }),
      transformResponse: (response: Response<UserDTO>): (UserDTO | null) => response.data || null,
    }),
    getUserByFirebaseId: build.query<UserDTO | null, { firebaseId: string }>({
      query: ({ firebaseId }) => ({
        url: '/users/auth',
        params: {
          firebase_id: firebaseId,
        },
        method: 'GET',
      }),
      transformResponse: (response: Response<UserDTO>): (UserDTO | null) => response.data || null,
    }),
    getUserByConfirmationCode: build.query<UserDTO | null, { confirmationCode: string }>({
      query: ({ confirmationCode }) => ({
        url: `/users/code/${confirmationCode}`,
        method: 'GET',
      }),
      transformResponse: (response: Response<UserDTO>): (UserDTO | null) => response.data || null,
    }),
    getBusinessUsers: build.query<UserDTO | null, { firebaseId: string }>({
      query: ({ firebaseId }) => ({
        url: '/users/auth',
        params: {
          firebase_id: firebaseId,
        },
        method: 'GET',
      }),
      transformResponse: (response: Response<UserDTO>): (UserDTO | null) => response.data || null,
    }),
    verifyUser: build.mutation<UserDTO | null, { userId: number, code: string }>({
      query: ({ userId, code }) => ({
        url: `/users/${userId}/verify`,
        method: 'POST',
        body: { verification_code: code },
      }),
      invalidatesTags: ['users'],
      transformResponse: (response: Response<UserDTO>): (UserDTO | null) => response.data || null,
    }),
    getUserBusinesses: build.query<BusinessDTO[], { userId: number }>({
      query: ({ userId }) => ({
        url: `/users/${userId}/businesses`,
        method: 'GET',
      }),
      transformResponse: (response: Response<BusinessDTO[]>): (BusinessDTO[]) => response.data || [],
    }),
    validateGoogleLogin: build.mutation<UserDTO | null, GoogleLoginDTO>({
      query: (data) => ({
        url: '/users/google-login',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: Response<UserDTO>): (UserDTO | null) => response.data || null,
    }),
  }),
});

export const {
  useLogInMutation, useGetUserByFirebaseIdQuery, useCreateUserMutation, useGetUserByConfirmationCodeQuery, useVerifyUserMutation, useGetUserBusinessesQuery, useValidateGoogleLoginMutation,
} = usersApi;
export { usersApi };
