/* eslint-disable no-nested-ternary */
import { Fragment, useEffect, useState } from 'react';
import { BsDownload, BsPlus } from 'react-icons/bs';
import { FiUserCheck, FiUsers } from 'react-icons/fi';
import axios from 'axios';
import { useSelector } from 'react-redux';
import PendingOrdersTable from '../components/PendingOrdersTable';
import { RootState, useGetEventByIdQuery, useGetEventOrdersQuery } from '../store';
import { OrderDTO } from '../models/order';
import Button from '../components/Button';
import OrderItem from '../types/OrderItem';
import config from '../config/config';
import IconInfoCard from '../components/IconInfoCard';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import ApprovedOrdersTable from '../components/ApprovedOrdersTable';
import Checkbox from '../components/Checkbox';
import useGetParams from '../hooks/useGetParams';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

const parseOrders = (orders: OrderDTO[]): OrderItem[] => orders
  .filter((o) => o.items && o.items.length > 0)
  .map((o) => ({
    name: `${o.items[0].name} ${o.items[0].last_name}`,
    person_id: o.items[0].person_id,
    id: o.id,
    amount: o.total_amount,
    order_status: o.status,
    item_id: o.items[0].id,
    first_name: o.items[0].name,
    last_name: o.items[0].last_name,
    email: o.email,
    order_id: o.id,
    is_manual: o.is_manual,
    created_at: o.created_at,
    order_display_id: o.display_id,
  }));

const filterNotActive = (orders: OrderItem[], showUalaPending: boolean) => orders.filter((o) => o.order_status === 'pending' && (showUalaPending ? !o.is_manual : o.is_manual));

const parseApprovedItems = (orders: OrderDTO[]): OrderItem[] => orders
  .filter((o) => o.status === 'paid')
  .map((o) => o.items.map((i) => ({
    id: o.id,
    name: `${i.name} ${i.last_name}`,
    person_id: i.person_id,
    order_status: o.status,
    amount: i.ticket?.price,
    item_id: i.id,
    first_name: i.name,
    last_name: i.last_name,
    email: o.email,
    order_id: o.id,
    is_manual: o.is_manual,
    created_at: o.created_at,
    order_display_id: o.display_id,
  })))
  .flat();

const EventOrders: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const { eventId, businessId } = useGetParams();
  const [pendingOrders, setPendingOrders] = useState<OrderItem[]>([]);
  const [activeOrders, setActiveOrders] = useState<OrderItem[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showUalaPending, setShowUalaPending] = useState(false);

  const { token, user: userData } = useSelector(
    (state: RootState) => state.auth,
  );

  if (!eventId || !+eventId || !businessId || !+businessId) {
    throw new Error('no event_id or business_id');
  }

  const { data: event, isLoading: isEventLoading } = useGetEventByIdQuery({ eventId });

  const { data: orders, isFetching } = useGetEventOrdersQuery({
    businessId: event?.business_id || 0,
    eventId: +eventId,
  }, { skip: !event });

  useEffect(() => {
    if (orders?.data) {
      const parsedOrders = parseOrders(orders.data);
      setPendingOrders(filterNotActive(parsedOrders, showUalaPending));
      setActiveOrders(parseApprovedItems(orders.data));
    }
  }, [orders, showUalaPending]);

  const handleDownloadExport = () => {
    setIsDownloading(true);
    axios
      .get(
        `${config.baseURL}/v1/businesses/${event?.business_id}/events/${eventId}/orders?xlsx=true`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'lista.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => console.error(e))
      .finally(() => setIsDownloading(false));
  };

  const handleAddOrder = () => navigate('new/tickets');

  const handleUalaCheckbox = () => {
    setShowUalaPending((value) => !value);
  };

  return (
    <div className="admin w-full">
      <div>
        <h1 className="text-2xl px-4 pt-4 pb-2 font-medium">
          Evento: {event?.name}
        </h1>
        {pendingOrders && (
          <div className="card border border-gray-200">
            {userData.type === 'ROOT' && <Checkbox label="Mostrar pendientes Uala" isChecked={showUalaPending} onCheckChange={handleUalaCheckbox} />}
            <div className="flex justify-between items-center py-2">
              <h1 className="text-lg">Solicitudes pendientes</h1>
              <label className="ml-2 inline-block text-xs px-2 rounded-full bg-gray-200 text-center mt-1.5">
                {pendingOrders.length} pendientes
              </label>
              <Button className="ml-10 text-lg" onClick={handleAddOrder}>
                <BsPlus />
              </Button>
            </div>
            {isFetching || isEventLoading ? (
              <OrdersTableSkeleton />
            ) : pendingOrders.length > 0 ? (
              <Fragment>
                <PendingOrdersTable
                  orderItems={pendingOrders}
                  eventId={+eventId}
                  businessId={+businessId}
                />
              </Fragment>
            ) : (
              <IconInfoCard
                title="Sin solicitudes pendientes"
                info="Aquí te aparecerán las solicitudes pendientes de clientes que necesitan entradas"
                icon={FiUserCheck}
                iconColor="text-indigo-600"
              />
            )}
          </div>
        )}
      </div>
      {activeOrders && (
        <div className="card border border-gray-200">
          <div className="flex justify-between py-2">
            <h1 className="text-lg">Lista de invitados</h1>
            <label className="ml-2 inline-block text-xs px-2 rounded-full bg-gray-200 text-center h-5 mt-1.5">
              {activeOrders.length} tickets
            </label>
            <Button onClick={handleDownloadExport} loading={isDownloading}>
              <BsDownload />
            </Button>
          </div>
          {(isFetching) ? (
            <OrdersTableSkeleton />
          ) : !isFetching && activeOrders?.length > 0 ? (
            <ApprovedOrdersTable orderItems={activeOrders} eventId={+eventId} businessId={event?.business_id || 0} />
          ) : (
            <IconInfoCard
              title="Aún no hay invitados confirmados"
              info="Aquí comenzarás a ver a los invitados una vez que sean aceptados"
              icon={FiUsers}
              iconColor="text-indigo-600"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default EventOrders;
