import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import EventMenuOption from '../components/EventMenuOption';
import { UserType } from '../models/user';
import {
  RootState,
  setSelectedBusiness,
  useGetAllBusinessesQuery,
  useGetUserBusinessesQuery,
} from '../store';
import Button from '../components/Button';
import LoadingLottie from '../components/LoadingLottie';
import { BusinessDTO } from '../models/business';
import IconInfoCard from '../components/IconInfoCard';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

interface Options {
  id: number;
  title: string;
  info: string;
  buttonLabel: string;
  redirectPath: string;
  allowedUsers: UserType[];
}

const BusinessMenu: React.FC = () => {
  const { token, user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();
  const { data: allBusinesses, isLoading: allBizLoading } = useGetAllBusinessesQuery(undefined, {
    skip: !user || user.type !== 'ROOT',
  });
  const { data: userBusinesses, isLoading: userBizLoading } = useGetUserBusinessesQuery(
    { userId: user.id },
    { skip: !user || user.type === 'ROOT' },
  );
  const [businesses, setBusinesses] = useState<BusinessDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Options[]>([]);

  useEffect(() => {
    setBusinesses(allBusinesses || userBusinesses || []);
    setIsLoading(allBizLoading || userBizLoading);
  }, [allBusinesses, userBusinesses, allBizLoading, userBizLoading]);

  useEffect(() => {
    setOptions(businesses.map((b) => ({
      id: b.id,
      title: b.name,
      info: b.description,
      buttonLabel: 'Seleccionar',
      redirectPath: `${b.id}/events`,
      allowedUsers: ['ROOT', 'COMMON'],
    })));
  }, [businesses]);

  if (isLoading) {
    return <LoadingLottie />;
  }

  const handleButtonClick = (id: number) => {
    const selected = allBusinesses?.find((b) => b.id === id);
    console.log({ selected, businesses: allBusinesses });
    if (selected) {
      dispatch(setSelectedBusiness(selected));
    }
  };

  const onClickCopyToken = () => {
    navigator.clipboard
      .writeText(token || '')
      .then(() => {
        toast.success('Token copiado al portapapeles');
      })
      .catch((err) => {
        console.error('Error al copiar al portapapeles: ', err);
      });
  };

  return (
    <div>
        {businesses && businesses.length === 0 && (
          <IconInfoCard
            title="Todavía no tenés negocios asociados"
            info="Aquí aparecerán los negocios asociados a tu usuario"
            icon={HiOutlineRocketLaunch}
            iconColor="text-indigo-600"
          />
        )}
      {options.map((option) => (
        <div key={option.id}>
          <EventMenuOption
            id={option.id}
            title={option.title}
            info={option.info}
            buttonLabel={option.buttonLabel}
            redirectPath={option.redirectPath}
            onButtonClick={handleButtonClick}
          />
        </div>
      ))}
      {user.type === 'ROOT' && (
        <div>
          <div className="flex flex-col justify-between px-20">
            <Button
              type="submit"
              primary
              className="mb-2"
              onClick={() => navigate('create-business')}
            >
              Crear nuevo negocio
            </Button>
          </div>
          <div className="flex flex-col justify-between px-20">
            <Button
              type="submit"
              primary
              className="mb-2"
              onClick={onClickCopyToken}
            >
              Copiar token
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessMenu;
