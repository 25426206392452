export const USER_ALREADY_INVITED = 'user has pending invitation';
export const USER_ALREADY_IN_BUSINESS = 'user already in business';
export const CODE_USED = 'code already used';

const parseErrors = (error: string) => {
  switch (error) {
    case USER_ALREADY_INVITED: return 'El usuario tiene una invitación pendiente';
    case USER_ALREADY_IN_BUSINESS: return 'El usuario ya pertenece a la organización';
    case CODE_USED: return 'El código no se puede repetir';
    default: return error;
  }
};

export default parseErrors;
