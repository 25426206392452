import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControlField from './FormControlField';
import Checkbox from './Checkbox';
import Button from './Button';
import {
  useCreateCouponMutation,
  useUpdateCouponMutation,
} from '../store';
import CouponDTO, { CreateCouponDTO } from '../types/Coupon';
import useGetParams from '../hooks/useGetParams';
import errorResponseToToast from '../utils/errorToastResponse';

interface Props {
  coupon?: CouponDTO;
  onSuccess: () => void;
}

interface FormData {
  code: string;
  isActive: boolean;
  quantity: number;
  value: number;
}

const validationSchema = yup.object<FormData>().shape({
  isActive: yup.boolean().required(),
  code: yup.string().required('Debes ingresar un código'),
  quantity: yup
    .number()
    .required('Debes ingresar una cantidad válida')
    .typeError('Debes ingresar un número'),
  value: yup
    .number()
    .min(1, 'Debes ingresar un valor mayor a 1')
    .max(100, 'Debes ingresar un valor que no supere 100')
    .required('Debes ingresar un número')
    .typeError('Debes ingresar un valor válido'),
});

const CreateEditCoupon: React.FC<Props> = ({ coupon, onSuccess }) => {
  const defaultCouponValue = (formCoupon: CouponDTO | undefined) => {
    if (!formCoupon) {
      return undefined;
    }
    if (formCoupon.type === 'percentage') {
      return formCoupon.value * 100;
    }
    return formCoupon.value;
  };

  const {
    register,
    handleSubmit,
    formState,
  } = useForm<FormData>({
    defaultValues: {
      isActive: coupon ? coupon.active : true,
      code: coupon?.code,
      quantity: coupon?.limit_amount,
      value: defaultCouponValue(coupon),
    },
    resolver: yupResolver<FormData>(validationSchema),
  });

  const { eventId, businessId } = useGetParams();

  const [createCoupon, resCreateCoupon] = useCreateCouponMutation();
  const [updateCoupon, resUpdateCoupon] = useUpdateCouponMutation();

  useEffect(() => {
    if (resCreateCoupon.isSuccess || resUpdateCoupon.isSuccess) {
      onSuccess();
    } else if (resCreateCoupon.error) {
      errorResponseToToast(resCreateCoupon.error);
    }
  }, [resCreateCoupon.isSuccess, resUpdateCoupon.isSuccess, onSuccess]);

  const parseFormToDto = (data: FormData): CreateCouponDTO => ({
    code: data.code,
    value: data.value / 100,
    limit_amount: data.quantity,
    active: data.isActive,
    type: 'percentage',
  });

  const onSubmit = async (data: FormData) => {
    const parsedCoupon = parseFormToDto(data);
    if (coupon) {
      updateCoupon({
        coupon: { ...parsedCoupon, id: coupon.id },
        eventId: +eventId,
        businessId,
      });
    } else {
      createCoupon({
        coupon: parsedCoupon,
        eventId: +eventId,
        businessId,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
        <div className="mb-4">
          <Checkbox
            label="Habilitado"
            labelClassName="text-gray-900"
            {...register('isActive')}
          ></Checkbox>
        </div>

        <FormControlField
          {...register('code', { required: 'This field is required' })}
          id="name"
          label="Nombre"
          labelAbove={true}
          errMsg={formState.errors.code?.message}
        />

        <div className="grid grid-cols-2 gap-4 mb-4">
          <FormControlField
            {...register('quantity', { required: true })}
            id="quantity"
            type="number"
            label="Cantidad"
            labelAbove={true}
            errMsg={formState.errors.quantity?.message}
          />
          <FormControlField
            {...register('value', { required: true })}
            id="value"
            type="number"
            label="% de descuento"
            labelAbove={true}
            errMsg={formState.errors.value?.message}
          />
        </div>
        <div className="flex flex-col justify-between">
          <Button
            type="submit"
            primary
            className="mb-2"
            disabled={!formState.isValid}
            loading={resCreateCoupon?.isLoading || resUpdateCoupon.isLoading}
          >
            Guardar cambios
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateEditCoupon;
