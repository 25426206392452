import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  RootState,
  useCreateOrderMutation,
  useGetCurrentAgentQuery,
} from '../store';
import OrderSummary from '../components/OrderSummary';
import FormControlField from '../components/FormControlField';
import config from '../config/config';
import GotCoupon from '../components/GotCoupon';
import CouponDTO from '../types/Coupon';
import OrderTotal from '../components/OrderTotal';
import useSendWhatsapp from '../hooks/useSendWhatsapp';
import generateWhatsappMessage from '../utils/generateWhatsappMessage';
import Button from '../components/Button';
import RadioButton from '../components/RadioButton';
import useQuery from '../hooks/useQuery';
import OpenPriceCard from '../components/OpenPriceCard';
import FormCardFrame from '../components/FormCardFrame';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

interface FormData {
  email: string;
  emailConfirm: string;
}

const noSpaces = (value: string) => !/\s/.test(value);
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validationSchema = yup.object<FormData>().shape({
  email: yup
    .string()
    .required('Debes ingresar un correo')
    .matches(emailRegex, 'Este correo no está bien')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
  emailConfirm: yup
    .string()
    .required('Debes ingresar un correo')
    .oneOf([yup.ref('email')], 'Los correos no coinciden')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
});

const CheckoutPage: React.FC = () => {
  const location = useLocation();
  const isBackoffice = location.pathname.includes('/admin/');

  const query = useQuery();
  const customized = query.get('customized') === 'true' || false;
  const skipPoll = query.get('skip-poll') === 'true' || false;
  const couponCode = query.get('coupon') ?? null;
  const refCode = query.get('ref') ?? null;

  const [appliedCoupon, setAppliedCoupon] = useState<CouponDTO | null>(null);
  const [openPriceAmount, setOpenPriceAmount] = useState<number | undefined>(
    undefined,
  );
  const [totalToPay, setTotalToPay] = useState<number>(0);
  const [clickedPay, setClickedPay] = useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);

  const [createOrder, results] = useCreateOrderMutation();

  const event = useSelector((state: RootState) => state.events.selectedEvent);

  const [paymentMethod, setPaymentMethod] = useState(
    event?.allow_online_payments ? 'online' : 'manual',
  );

  const { orderItemData, pollData } = useSelector(
    (state: RootState) => state.orders,
  );

  const { data: agent } = useGetCurrentAgentQuery(
    { businessId: event?.business_id || 0, eventId: event?.id || 0 },
    { skip: !event || !event.allow_manual_payments || isBackoffice },
  );

  const form = useForm<FormData>({
    defaultValues: {
      email: '',
      emailConfirm: '',
    },
    resolver: yupResolver<FormData>(validationSchema),
  });

  const navigate = useNavigateWithQuery();

  useEffect(() => {
    if (!event || !orderItemData) {
      navigate('../../');
    }
  }, [event, orderItemData, navigate]);

  if (!event || !orderItemData) {
    return null;
  }

  const selectedTickets = orderItemData.map((item) => item.ticket);

  const hasOpenPriceTicket = selectedTickets.find((t) => t.has_open_price);

  const { control, handleSubmit, formState } = form;

  const handleCouponChange = (coupon: CouponDTO | null) => {
    setAppliedCoupon(coupon);
  };

  const handleAmountChange = (amount: number | undefined) => {
    setOpenPriceAmount(amount);
  };

  const handleCalculatedTotalToPay = (total: number) => {
    setTotalToPay(total);
  };

  const getPayButtonLabel = () => {
    if (isBackoffice || totalToPay === 0) {
      return 'Confirmar';
    }
    if (paymentMethod === 'online') {
      return 'Pagar';
    }
    if (paymentMethod === 'manual') {
      return 'Solicitar entradas';
    }
    return 'Confirmar';
  };

  useEffect(() => {
    setShowPaymentOptions(
      !!(
        event.allow_manual_payments
        && event.allow_online_payments
        && agent?.phone_number
        && !isBackoffice
        && totalToPay > 0
      ),
    );
  }, [event, agent, isBackoffice, totalToPay]);

  const handlePay = () => {
    setClickedPay(true);
    // revisar
    const action = createOrder({
      order: {
        email: form.getValues('email'),
        items: orderItemData.map((item) => ({
          name: item.name,
          last_name: item.last_name,
          person_id: item.person_id,
          ticket_id: item.ticket.id,
        })),
        coupon_id: appliedCoupon?.id,
        open_price_amount: openPriceAmount,
        poll_responses: pollData,
      },
      businessId: event.business_id,
      eventId: event.id,
      isManual: paymentMethod === 'manual' || isBackoffice || totalToPay === 0,
      skipPoll,
      referralCode: refCode,
    });

    action
      .unwrap()
      .then((res) => {
        const orderData = res.data;
        if (orderData) {
          if (isBackoffice) {
            toast.success('Orden creada', {
              duration: 5000,
              position: 'bottom-center',
            });
            return navigate('../../');
          }
          if (totalToPay === 0) {
            return navigate(`/order-success?id=${orderData.custom_id}`);
          }
          const checkoutLink = orderData.payments?.[0]?.checkout_link;
          if (!orderData.is_manual) {
            if (checkoutLink) {
              window.location.href = checkoutLink;
            }
          } else {
            useSendWhatsapp(
              agent?.phone_number || event.phone,
              generateWhatsappMessage(
                event,
                orderData,
                orderItemData,
                totalToPay,
              ),
            );
            return navigate(`/order-success?id=${orderData.custom_id}`);
          }
        } else {
          throw new Error('no order data');
        }
        return '';
      })
      .catch(async (res) => {
        console.error(res);
        setClickedPay(false);
        if (res?.data?.error === 'no tickets available to fulfill order') {
          toast.error('Lo sentimos, las entradas que habías solicitado se agotaron.', {
            duration: 10000,
            position: 'bottom-center',
          });
          return navigate('../tickets');
        }
        toast.error('Se produjo un error al crear la orden. Intenta nuevamente', {
          duration: 5000,
          position: 'bottom-center',
        });
        return '';
      });
  };

  return (
    <div className="space-y-2 mt-3">
      <div className="flex justify-center">
        <OrderSummary
          hasOpenPrice={!!hasOpenPriceTicket}
          items={orderItemData}
          customized={customized}
        />
      </div>
      <form className="mb-7" onSubmit={handleSubmit(handlePay)}>
        <div className="my-5">
          <FormCardFrame>
            <h2 className="text-lg font-medium">Correo electrónico</h2>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormControlField
                  label="Correo electrónico"
                  id="email"
                  {...field}
                  onChange={(e) => field.onChange(e.target.value.replace(/\s/g, ''))
                  }
                  errMsg={formState.errors.email?.message}
                />
              )}
            />
            <Controller
              name="emailConfirm"
              control={control}
              render={({ field }) => (
                <FormControlField
                  label="Confirmar correo electrónico"
                  id="emailConfirm"
                  {...field}
                  onChange={(e) => field.onChange(e.target.value.replace(/\s/g, ''))
                  }
                  autoComplete={
                    config.production && !isBackoffice
                      ? 'idontwantthistoeverautocompleteokok??'
                      : 'email'
                  }
                  onPaste={(e) => (config.production && !isBackoffice ? e.preventDefault() : e)}
                  errMsg={formState.errors.emailConfirm?.message}
                />
              )}
            />
          </FormCardFrame>
        </div>
        {((totalToPay > 0 && !hasOpenPriceTicket) || appliedCoupon) && (
          <GotCoupon loadedCode={couponCode} onCouponChange={handleCouponChange} />
        )}
        {hasOpenPriceTicket && (
          <OpenPriceCard
            ticket={selectedTickets[0]}
            onAmountChange={handleAmountChange}
          />
        )}
        {showPaymentOptions && (
          <div className="my-5 py-2 ml-1 flex flex-col space-y-2">
            <h2 className="text-lg font-medium">Método de Pago</h2>
            <div className="ml-2">
              <RadioButton
                name="paymentMethod"
                value="online"
                checked={paymentMethod === 'online'}
                description="Realizarás el pago online a través de Ualá y recibirás las entradas en tu correo."
                label="Tarjeta de crédito/débito"
                disabled={clickedPay}
                onChange={() => setPaymentMethod('online')}
              />
              <RadioButton
                name="paymentMethod"
                value="manual"
                description="Enviarás un mensaje por WhatsApp a una operadora que te indicará como realizar el pago."
                checked={paymentMethod === 'manual'}
                label="Transferencia"
                disabled={clickedPay}
                onChange={() => setPaymentMethod('manual')}
              />
            </div>
          </div>
        )}
        <OrderTotal
          hasOpenPrice={!!hasOpenPriceTicket}
          openPrice={openPriceAmount}
          event={event}
          selectedTickets={selectedTickets}
          appliedCoupon={appliedCoupon}
          onCalculatedTotalToPay={handleCalculatedTotalToPay}
          isManual={paymentMethod === 'manual' || isBackoffice}
        />
        <div className="flex flex-col justify-center my-10">
          <Button
            type="submit"
            className="mb-2 h-12 text-lg"
            primary
            loading={results.isLoading}
            disabled={hasOpenPriceTicket && !openPriceAmount}
          >
            {getPayButtonLabel()}
          </Button>
          <Button
            type="button"
            transparent
            onClick={() => navigate('../entry-data')}
          >
            Volver
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutPage;
