import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControlField from './FormControlField';
import { userPermissionsArray } from '../models/user';
import Select from './Select';
import Button from './Button';
import { useCreateInvitationMutation } from '../store';
import { toastError, toastSucces } from '../utils/toasts';
import errorResponseToToast from '../utils/errorToastResponse';

interface Props {
  businessId: number;
  onSuccess: () => void;
}

interface FormData {
  email: string;
  permissions: string;
}

const noSpaces = (value: string) => !/\s/.test(value);
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validationSchema = yup.object<FormData>().shape({
  email: yup
    .string()
    .required('Debes ingresar un correo')
    .matches(emailRegex, 'Este correo no está bien')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
  permissions: yup
    .string()
    .required('Debes seleccionar una opción')
    .oneOf(userPermissionsArray, 'La opción seleccionada no es válida'),
});

const InviteUser: React.FC<Props> = ({ businessId, onSuccess }) => {
  const form = useForm<FormData>({
    defaultValues: {
      email: '',
      permissions: 'SCANNER',
    },
    resolver: yupResolver<FormData>(validationSchema),
  });

  const { control, handleSubmit, formState } = form;

  const [createInvitation, createInvitationResults] = useCreateInvitationMutation();

  const handleSendInvite = async () => {
    try {
      const result = await createInvitation({ businessId, email: form.getValues('email'), permissions: form.getValues('permissions') });
      if ('error' in result) {
        return errorResponseToToast(result.error);
      }
      toastSucces('Invitación enviada con éxito');
      return onSuccess();
    } catch (e) {
      console.error(e);
      return toastError('Se produjo un error al enviar la invitación');
    }
  };

  return (
    <div>
      <form className="w-full h-full" onSubmit={handleSubmit(handleSendInvite)}>
        <div className="mb-5">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormControlField
                label="Correo electrónico"
                labelAbove={true}
                id="email"
                {...field}
                onChange={(e) => field.onChange(e.target.value.replace(/\s/g, ''))
                }
                errMsg={formState.errors.email?.message}
              />
            )}
          />
        </div>
        <div className="mb-5">
          <Controller
            name="permissions"
            control={control}
            render={({ field }) => (
              <Select
                label="Nivel de permisos"
                id="permissions"
                options={userPermissionsArray.map((p) => ({
                  label: p,
                  value: p,
                }))}
                {...field}
              />
            )}
          />
        </div>
        <div className="flex justify-center align-items-center">
          <Button className="w-full" primary loading={createInvitationResults.isLoading}>
            Enviar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InviteUser;
