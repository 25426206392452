import React, { useEffect, useState } from 'react';
import TicketDTO from '../models/ticket';
import CouponDTO from '../types/Coupon';
import EventDTO from '../models/event';
import { formatCurrency, parse2decimals } from '../utils/shared';

interface OrderTotalProps {
  hasOpenPrice: boolean;
  openPrice: number | undefined,
  selectedTickets: TicketDTO[];
  event: EventDTO;
  appliedCoupon: CouponDTO | null;
  isManual: boolean;
  onCalculatedTotalToPay: (total: number) => void;
}

const OrderTotal: React.FC<OrderTotalProps> = ({
  hasOpenPrice,
  openPrice,
  selectedTickets,
  event,
  appliedCoupon,
  isManual,
  onCalculatedTotalToPay,
}) => {
  const [baseTotal, setBaseTotal] = useState(0);
  const [discounts, setDiscounts] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [total, setTotal] = useState(0);

  const getTicketAmount = (ticket: TicketDTO) => {
    if (hasOpenPrice) {
      if (openPrice) {
        return openPrice;
      }
      return 0;
    }
    return ticket.price;
  };

  const calculateBaseTotal = () => parse2decimals(selectedTickets.reduce((pv, ticket) => pv + getTicketAmount(ticket), 0));

  const calculateDiscounts = (base: number) => parse2decimals((appliedCoupon ? appliedCoupon.value * base : 0));

  const calculateServiceCharge = (subtotal: number) => parse2decimals(subtotal * (isManual ? event.manual_service_charge : event.online_service_charge));

  useEffect(() => {
    if (selectedTickets) {
      const base = calculateBaseTotal();
      setBaseTotal(base);
      const d = calculateDiscounts(base);
      setDiscounts(d);
      const s = calculateServiceCharge(base - d);
      setServiceCharge(s);
      const totalToPay = parse2decimals(base - d + s);
      setTotal(totalToPay);
      onCalculatedTotalToPay(totalToPay);
    }
  }, [selectedTickets]);

  return (
    <div>
      <div className="flex flex-col px-3 my-3">
        <span className="text-sm mb-1 ml-1 font-medium">Subtotal: <span className={`${discounts > 0 ? 'line-through font-light' : ''}`}>{formatCurrency(baseTotal)}</span> {discounts > 0 && formatCurrency(baseTotal - discounts)}</span>
        <span className="text-sm ml-1">Service charge: {formatCurrency(serviceCharge)}</span>
        <span className="text-lg mt-3 font-medium mb-4">Total a pagar: {formatCurrency(total)}</span>
      </div>
    </div>
  );
};

export default OrderTotal;
