import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EventCard from '../components/EventCard';
import { RootState, useGetBusinessByIdQuery } from '../store';
import EventCardSkeleton from '../components/EventCardSkeleton';
import { setSelectedEvent } from '../store/slices/events';
import Button from '../components/Button';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

const AdminEvents: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const dispatch = useDispatch();

  const { business_id: businessId } = useParams();
  if (!businessId || !+businessId) {
    throw new Error('no business id');
  }

  const userData = useSelector((state: RootState) => state.auth.user);

  const { data: business, isLoading } = useGetBusinessByIdQuery(+businessId);

  const handleEventSelect = (eventId: number) => {
    const event = business?.events?.find((e) => e.id === eventId);
    if (event) {
      dispatch(setSelectedEvent(event));
    }
    navigate(`${eventId}`);
  };
  return (
    <div className="p-3">
      <h1 className="text-2xl mb-10">Bienvenido {business?.name}</h1>
      <h2 className="text-xl">TUS EVENTOS</h2>
      {isLoading && <EventCardSkeleton />}
      <div className="flex flex-col items-center">
        {!isLoading
          && business?.events?.map((event) => (
            <EventCard
              key={event.id}
              event={event}
              onSelect={handleEventSelect}
            />
          ))}
      </div>
      {userData.type === 'ROOT' && <div className="flex flex-col justify-between px-20">
          <Button
            type="submit"
            primary
            className="mb-2"
            onClick={() => navigate('create')}
          >
            Crear nuevo evento
          </Button>
        </div>}
    </div>
  );
};
export default AdminEvents;
