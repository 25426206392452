import './index.css';
import {
  Outlet, Route, Routes, useLocation,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import CreateOrderSuccess from './pages/CreateOrderSuccess';
import CreateOrderError from './pages/CreateOrderError';
import LogIn from './pages/LogIn';
import AdminEvents from './pages/AdminEvents';
import EventOrders from './pages/EventOrders';
import Header from './components/Header';
import QRCodeScanner from './components/QRCodeScanner';
import EventMenu from './pages/EventMenu';
import AdminTickets from './pages/AdminTickets';
import CRUDCoupon from './components/CRUDCoupon';
import AvailableEventsPage from './pages/AvailableEventsPage';
import EventPage from './pages/EventPage';
import AdminOutlet from './pages/AdminOutlet';
import SelectTicketsPage from './pages/SelectTicketsPage';
import CompleteEntryFieldsPage from './pages/CompleteEntryFields';
import CheckoutPage from './pages/CheckoutPage';
import CreateOrderOutlet from './components/CreateOrderOutlet';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import EventEditor from './components/EventEditor';
import BusinessMenu from './pages/BusinessMenu';
import PaymentsPage from './pages/PaymentsPage';
import QuestionsPage from './pages/QuestionsPage';
import PollsPage from './pages/PollsPage';
import BusinessEditor from './components/BusinessEditor';
import AdminUsersPage from './pages/AdminUsersPage';
import InvitationsPage from './pages/InvitationsPage';
import SignUpPage from './pages/SignUpPage';
import SignUpConfirmPage from './pages/SignUpConfirmPage';
import AdminReferralsPage from './pages/AdminReferralsPage';

function App() {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');
  return (
    <div className="flex flex-col h-screen">
      <ScrollToTop />
      <Header />
      <div>
        <Toaster />
      </div>
      <div className="flex-grow bg-gray-50 text-gray-600">
        <Routes>
          <Route path="/" element={<AvailableEventsPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/signup-confirm" element={<SignUpConfirmPage />} />
          <Route path="/events/:event_id" element={<Outlet />}>
            <Route index element={<EventPage />} />
            <Route path="orders/new" element={<CreateOrderOutlet />}>
              <Route path="tickets" element={<SelectTicketsPage />} />
              <Route path="entry-data" element={<CompleteEntryFieldsPage />} />
              <Route path="poll" element={<QuestionsPage />} />
              <Route path="checkout" element={<CheckoutPage />} />
            </Route>
          </Route>
          <Route path="/order-success" element={<CreateOrderSuccess />} />
          <Route path="/order-error" element={<CreateOrderError />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/admin" element={<AdminOutlet />}>
            <Route index element={<BusinessMenu />} />
            <Route path="invitations" element={<InvitationsPage />} />
            <Route path="create-business" element={<BusinessEditor create={true} />} />
            <Route path=":business_id/events" element={<Outlet />}>
              <Route index element={<AdminEvents />} />
              <Route path="create" element={<EventEditor create={true} />} />
              <Route path=":event_id" element={<Outlet />}>
                <Route index element={<EventMenu />} />
                <Route path="scanner" element={<QRCodeScanner />} />
                <Route path="orders" element={<Outlet />}>
                  <Route index element={<EventOrders />} />
                  <Route path="new" element={<CreateOrderOutlet />}>
                    <Route path="tickets" element={<SelectTicketsPage />} />
                    <Route path="entry-data" element={<CompleteEntryFieldsPage />} />
                    <Route path="poll" element={<QuestionsPage />} />
                    <Route path="checkout" element={<CheckoutPage />} />
                  </Route>
                </Route>
                <Route path="tickets" element={<AdminTickets />} />
                <Route path="users" element={<AdminUsersPage />} />
                <Route path="coupons" element={<CRUDCoupon />} />
                <Route path="referrals" element={<AdminReferralsPage />} />
                <Route path="payments" element={<PaymentsPage />} />
                <Route path="polls" element={<PollsPage />} />
                <Route path="edit" element={<EventEditor create={false} />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </div>
      {!isAdminRoute && <Footer />}
    </div>
  );
}

export default App;
