export type UserType = 'ROOT' | 'COMMON';
export type UserRole = UserPermissions | 'ROOT';

export type UserPermissions = 'ADMIN' | 'EDITOR' | 'SCANNER';
export const userPermissionsArray = ['ADMIN', 'EDITOR', 'SCANNER'];

export interface UserBusiness {
  business_id: number;
  user_id: number;
  active: boolean;
  permissions: UserPermissions;
}

export interface AuthUserData {
  id: number;
  username: string,
  type: UserType,
  email: string
  user_businesses: UserBusiness[]
}
export default interface UserDTO {
  id: number;
  email: string;
  username: string;
  firebaseId: string;
  type: UserType;
  is_verified: boolean;
  verification_code: string;
  user_businesses: UserBusiness[];
}
