import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import FormControlField from './FormControlField';
import Checkbox from './Checkbox';
import Button from './Button';
import {
  setSelectedBusiness,
  useCreateBusinessMutation,
  useUpdateBusinessMutation,
} from '../store';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

interface Props {
  create: boolean;
}

interface FormData {
  name: string;
  description: string;
  active: boolean;
}

const validationSchema = yup.object<FormData>().shape({
  name: yup.string().required('Debes ingresar un nombre'),
  description: yup.string().required('Debes ingresar una descripción'),
  active: yup.boolean().required(),
});

const BusinessEditor: React.FC<Props> = ({ create }) => {
  const navigate = useNavigateWithQuery();
  const { business_id: id } = useParams<{ business_id: string }>();

  const [business, setBusiness] = useState<FormData | null>(null);

  useEffect(() => {
    if (create) {
      setBusiness(null);
    } else {
      setBusiness(null);
    }
  }, [create]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      description: '',
      active: true,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (business) {
      reset({
        name: business?.name || '',
        description: business?.description || '',
        active: business ? business.active : true,
      });
    }
  }, [business, reset]);

  const [createBusiness, resCreateBusiness] = useCreateBusinessMutation();
  const [updateBusiness, resUpdateBusiness] = useUpdateBusinessMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (resCreateBusiness.isSuccess || resUpdateBusiness.isSuccess) {
      toast.success(`Negocio ${create ? 'creado' : 'actualizado'} con éxito`, {
        duration: 5000,
        position: 'bottom-center',
      });
      if (resUpdateBusiness.data?.data) {
        dispatch(setSelectedBusiness(resUpdateBusiness.data.data));
      }
      navigate('..');
    }
  }, [resCreateBusiness.isSuccess, resUpdateBusiness.isSuccess]);

  const onSubmit = async (data: FormData) => {
    try {
      if (business) {
        await updateBusiness({
          business: { ...data, id: id ? +id : 0 },
        });
      } else {
        await createBusiness({
          business: { ...data, service_charge: 0.1, logo: 'logo' },
        });
      }
    } catch (e) {
      console.error(e);
      toast.error('Ocurrió un error', {
        duration: 5000,
        position: 'bottom-center',
      });
    }
  };

  return (
    <div className="px-5 my-5">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
        <FormControlField
          {...register('name')}
          id="name"
          label="Nombre"
          labelAbove={true}
          errMsg={errors.name?.message}
        />
        <FormControlField
          {...register('description')}
          id="description"
          label="Descripción"
          labelAbove={true}
          errMsg={errors.description?.message}
        />
        <div className="mb-4">
          <Checkbox
            label="Activo"
            labelClassName="text-gray-900"
            {...register('active')}
          />
        </div>
        <div className="flex flex-col justify-between">
          <Button
            type="submit"
            primary
            className="mb-2"
            disabled={!isValid}
            loading={resCreateBusiness.isLoading || resUpdateBusiness.isLoading}
          >
            Guardar cambios
          </Button>
          <Button
            type="button"
            transparent
            onClick={() => navigate('..')}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BusinessEditor;
