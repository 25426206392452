import classNames from 'classnames';
import { FC, SelectHTMLAttributes, forwardRef } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Tooltip from './Tooltip';

export interface Option {
  label: string;
  value: string | number;
}

interface CustomSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  id: string;
  label: string;
  options: Option[];
  errMsg?: string;
  tooltipText?: string;
}

const Select: FC<CustomSelectProps> = forwardRef<
HTMLSelectElement,
CustomSelectProps
>(({
  id, label, errMsg, tooltipText, options, ...rest
}, ref) => {
  const commonClasses = 'text-black rounded-lg p-3 border focus:outline-none focus:ring-2 focus:ring-blue-500';

  const selectClasses = classNames(
    commonClasses,
    'w-full',
    {
      'border-red-500': errMsg,
    },
  );

  return (
    <div className="form-control flex flex-col mb-2 w-full">
      <div className='flex items-center w-full'>
        <label htmlFor={id} className="text-left">
          {label}
        </label>
        {tooltipText && (
          <Tooltip content={tooltipText}>
            <AiOutlineQuestionCircle className='ml-1' />
          </Tooltip>
        )}
      </div>
      <div className="flex items-center">
        <select id={id} ref={ref} className={selectClasses} {...rest}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <span className="text-red-500 white-text-shadow">{errMsg}</span>
    </div>
  );
});

export default Select;
