import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import SortableTable from './SortableTable';
import UserDTO, { UserBusiness } from '../models/user';

interface Props {
  users: UserDTO[];
  businessId: number;
}

type ParsedUser = UserDTO & UserBusiness;

const parseUser = (businessId: number, user: UserDTO) : ParsedUser => {
  const userBiz = user.user_businesses.find((ub) => ub.business_id === businessId);
  if (!userBiz) {
    throw new Error('no user business found');
  }
  return {
    ...user,
    ...userBiz,
  };
};

const UsersTable: React.FC<Props> = ({ users, businessId }) => {
  const [parsedUsers, setParsedUsers] = useState<ParsedUser[]>([]);

  useEffect(() => {
    setParsedUsers(users.map((u) => parseUser(businessId, u)));
  }, [users]);

  const config = [
    {
      label: 'Correo',
      render: (user: ParsedUser) => user.email,
    },
    {
      label: 'Permisos',
      render: (user: ParsedUser) => user.permissions,
    },
    {
      label: 'Activo',
      render: (user: ParsedUser) => <div className='flex justify-center items-center'> {user.active ? <AiFillCheckCircle className=' text-green-600' /> : <AiFillCloseCircle className='text-red-600' />}</div>,
    },
  ];

  const keyFn = (user: UserDTO) => `${user.id}`;

  return (
    <div>
      <SortableTable data={parsedUsers} config={config} keyFn={keyFn} pagination={false} search={false}/>
    </div>
  );
};

export default UsersTable;
