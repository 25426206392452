import { useSelector } from 'react-redux';
import EventMenuOption from '../components/EventMenuOption';
import authenticate from '../utils/authenticate';
import { UserRole } from '../models/user';
import { RootState } from '../store';
import useGetParams from '../hooks/useGetParams';

interface Options {
  title: string;
  info: string;
  buttonLabel: string;
  redirectPath: string;
  allowedUsers: UserRole[];
}

const EventMenu: React.FC = () => {
  const { eventId, businessId } = useGetParams();
  const userData = useSelector((state: RootState) => state.auth.user);
  const role: UserRole = userData.type === 'ROOT' ? 'ROOT' : userData.user_businesses.find((ub) => ub.business_id === businessId)?.permissions || 'SCANNER';
  const options: Options[] = [
    {
      title: 'Lista de asistentes',
      info: 'En este apartado encontrarás la acción de aceptar solicitudes de entradas, y podrás visualizar los asistentes al evento.',
      buttonLabel: 'Ver lista',
      redirectPath: 'orders',
      allowedUsers: ['EDITOR', 'ADMIN'],
    },
    {
      title: 'Validador de entradas',
      info: 'Con esta funcionalidad podrás validar las entradas con QR, o con DNI.',
      buttonLabel: 'Lector QR',
      redirectPath: 'scanner',
      allowedUsers: ['EDITOR', 'ADMIN', 'SCANNER'],
    },
    {
      title: 'Configuración de entradas',
      info: 'Aquí podras crear los diferentes tipos de entradas de tu evento, configurar su precio y cantidad de cada una.',
      buttonLabel: 'Editar entradas',
      redirectPath: 'tickets',
      allowedUsers: ['ADMIN'],
    },
    {
      title: 'Configuración de cupones',
      info: 'Aquí podras crear y modificar los cupones de descuento de tu evento.',
      buttonLabel: 'Editar cupones',
      redirectPath: 'coupons',
      allowedUsers: ['ADMIN'],
    },
    {
      title: 'Referidos',
      info: 'Aquí ver y configurar los referidos del evento.',
      buttonLabel: 'Ver referidos',
      redirectPath: 'referrals',
      allowedUsers: ['ADMIN'],
    },
    {
      title: 'Encuestas',
      info: 'Aquí podras ver las respuestas a las preguntas de tu evento.',
      buttonLabel: 'Ver respuestas',
      redirectPath: 'polls',
      allowedUsers: ['ADMIN'],
    },
    {
      title: 'Usuarios',
      info: 'Aquí podras gestionar los usuarios de tu organización.',
      buttonLabel: 'Ver usuarios',
      redirectPath: 'users',
      allowedUsers: ['ADMIN'],
    },
    {
      title: 'Pagos',
      info: 'Aquí podrás ver información de los pagos del evento',
      buttonLabel: 'Ver pagos',
      redirectPath: 'payments',
      allowedUsers: ['ROOT'],
    },
    {
      title: 'Configuración del evento',
      info: 'Aquí podrás editar la información del evento',
      buttonLabel: 'Editar evento',
      redirectPath: 'edit',
      allowedUsers: ['ROOT'],
    },
  ];
  return (
    <div>
      {options.map((option) => (
        <div key={option.title}>
          {authenticate(option.allowedUsers, role) && (
            <EventMenuOption
              id={+eventId ?? 0}
              title={option.title}
              info={option.info}
              buttonLabel={option.buttonLabel}
              redirectPath={option.redirectPath}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default EventMenu;
