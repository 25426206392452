import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import getErrorResponse from './getErrorResponse';
import { toastError } from './toasts';
import parseErrors from './parseErrors';

const errorResponseToToast = (response: FetchBaseQueryError | SerializedError) => {
  const errorData = getErrorResponse(response);
  return toastError(parseErrors(errorData.error));
};

export default errorResponseToToast;
