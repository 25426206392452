import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import FormControlField from './FormControlField';
import Checkbox from './Checkbox';
import Button from './Button';
import {
  RootState,
  useCreateEventMutation,
  useGetEventByIdQuery,
  useUpdateEventMutation,
} from '../store';
import FormControlTextArea from './FormControlTextArea';
import { setSelectedEvent } from '../store/slices/events';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

interface Props {
  create: boolean;
}

interface FormData {
  name: string;
  description: string;
  date: string;
  time: string;
  place: string;
  active: boolean;
  terms: string;
  phone: string;
  banner_image: string;
  maps_link: string;
  allow_online_payments: boolean;
  allow_manual_payments: boolean;
  online_service_charge: number;
  manual_service_charge: number;
  min_age: number;
  address: string;
  require_id_tickets: boolean;
  receives_wire_transfers: boolean;
  has_polls: boolean;
  is_public: boolean;
}

const combineDateAndTime = (date: string, time: string) => {
  const [year, month, day] = date.split('-');
  const [hours, minutes] = time.split(':');
  const combinedDate = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10),
    parseInt(hours, 10),
    parseInt(minutes, 10),
  );
  const localOffset = combinedDate.getTimezoneOffset() * 60000; // Offset in milliseconds
  const localTime = new Date(combinedDate.getTime() - localOffset);
  return localTime.toISOString();
};

const validationSchema = yup.object<FormData>().shape({
  name: yup.string().required('Debes ingresar un nombre'),
  description: yup.string().required('Debes ingresar una descripción'),
  date: yup.string().required('Debes ingresar una fecha'),
  time: yup.string().required('Debes ingresar una hora'),
  place: yup.string().required('Debes ingresar un lugar'),
  active: yup.boolean().required(),
  require_id_tickets: yup.boolean().required(),
  receives_wire_transfers: yup.boolean().required(),
  is_public: yup.boolean().required(),
  has_polls: yup.boolean().required(),
  terms: yup.string().required('Debes ingresar los términos'),
  phone: yup.string().required('Debes ingresar un teléfono'),
  banner_image: yup
    .string()
    .url('Debe ser una URL válida')
    .required('Debes ingresar una URL para la imagen'),
  maps_link: yup
    .string()
    .url('Debe ser una URL válida')
    .required('Debes ingresar una URL para el mapa'),
  allow_online_payments: yup.boolean().required(),
  allow_manual_payments: yup.boolean().required(),
  online_service_charge: yup
    .number()
    .required('Debes ingresar un cargo por servicio')
    .min(0, 'El cargo por servicio debe ser al menos 0')
    .max(1, 'El cargo por servicio no puede ser mayor que 1')
    .typeError('Debe ser un número'),
  manual_service_charge: yup
    .number()
    .required('Debes ingresar un cargo por servicio')
    .min(0, 'El cargo por servicio debe ser al menos 0')
    .max(1, 'El cargo por servicio no puede ser mayor que 1')
    .typeError('Debe ser un número'),
  min_age: yup
    .number()
    .required('Debes ingresar una edad mínima')
    .typeError('Debe ser un número'),
  address: yup.string().required('Debes ingresar una dirección'),
});

const formatDate = (date: string | Date) => {
  const d = new Date(date);
  const month = `${d.getMonth() + 1}`.padStart(2, '0');
  const day = `${d.getDate()}`.padStart(2, '0');
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

const EventEditor: React.FC<Props> = ({ create }) => {
  const navigate = useNavigateWithQuery();
  const selectedEvent = useSelector(
    (state: RootState) => state.events.selectedEvent,
  );
  const { event_id: eventId, business_id: businessId } = useParams<{ event_id: string, business_id: string }>();
  const { data: loadedEvent, isLoading: eventLoading } = useGetEventByIdQuery(
    { eventId: +(eventId || 0) },
    { skip: !eventId },
  );

  const [event, setEvent] = useState<FormData | null>(null);

  useEffect(() => {
    if (create) {
      setEvent(null);
    } else {
      setEvent(selectedEvent || loadedEvent || null);
    }
  }, [selectedEvent, loadedEvent, create]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      description: '',
      date: new Date().toISOString().split('T')[0],
      time: '',
      place: '',
      active: true,
      terms: '',
      phone: '',
      banner_image: '',
      maps_link: '',
      allow_online_payments: true,
      allow_manual_payments: true,
      online_service_charge: 0.1,
      manual_service_charge: 0.05,
      min_age: 0,
      address: '',
      require_id_tickets: false,
      receives_wire_transfers: false,
      has_polls: false,
      is_public: true,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (event) {
      reset({
        ...{
          name: event?.name || '',
          description: event?.description || '',
          date: event?.date
            ? event.date
            : new Date().toISOString().split('T')[0],
          time: event?.time ?? '',
          place: event?.place ?? '',
          active: event ? event.active : true,
          require_id_tickets: event ? event.require_id_tickets : false,
          terms: event?.terms ?? '',
          phone: event?.phone ?? '',
          banner_image: event?.banner_image ?? '',
          maps_link: event?.maps_link ?? '',
          allow_online_payments: event ? event.allow_online_payments : true,
          allow_manual_payments: event ? event.allow_manual_payments : true,
          online_service_charge: event?.online_service_charge ?? 0.1,
          manual_service_charge: event?.manual_service_charge ?? 0.05,
          min_age: event?.min_age ?? 0,
          address: event?.address ?? '',
          receives_wire_transfers: event?.receives_wire_transfers ?? false,
          has_polls: event?.has_polls ?? true,
          is_public: event?.is_public ?? true,
        },
        date: event.date
          ? formatDate(event.date)
          : new Date().toISOString().split('T')[0],
      });
    }
  }, [event, reset]);

  const [createEvent, resCreateEvent] = useCreateEventMutation();
  const [updateEvent, resUpdateEvent] = useUpdateEventMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (resCreateEvent.isSuccess || resUpdateEvent.isSuccess) {
      toast.success(`Evento ${create ? 'creado' : 'actualizado'} con éxito`, {
        duration: 5000,
        position: 'bottom-center',
      });
      if (resUpdateEvent.data?.data) {
        dispatch(setSelectedEvent(resUpdateEvent.data.data));
      }
      navigate('..');
    }
  }, [resCreateEvent.isSuccess, resUpdateEvent.isSuccess]);

  const onSubmit = async (data: FormData) => {
    if (!businessId) {
      throw new Error('business not found');
    }
    try {
      const eventDateTime = combineDateAndTime(data.date, data.time);
      if (event) {
        await updateEvent({
          event: { ...data, date: eventDateTime, id: eventId ? +eventId : 0 },
          businessId: +(businessId || 0),
        });
      } else {
        await createEvent({
          event: { ...data, date: eventDateTime },
          businessId: +(businessId || 0),
        });
      }
    } catch (e) {
      console.error(e);
      toast.error('Ocurrió un error', {
        duration: 5000,
        position: 'bottom-center',
      });
    }
  };

  if (eventLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="px-5 my-5">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
        <div className="mb-4">
          <Checkbox
            label="Activo"
            labelClassName="text-gray-900"
            {...register('active')}
          />
        </div>
        <div className="mb-4">
          <Checkbox
            label="Identificación de entradas obligatoria"
            labelClassName="text-gray-900"
            {...register('require_id_tickets')}
          />
        </div>
        <div className="mb-4">
          <Checkbox
            label="Es público"
            labelClassName="text-gray-900"
            {...register('is_public')}
          />
        </div>
        <div className="mb-4">
          <Checkbox
            label="Tiene encuesta"
            labelClassName="text-gray-900"
            {...register('has_polls')}
          />
        </div>
        <FormControlField
          {...register('name')}
          id="name"
          label="Nombre"
          labelAbove={true}
          errMsg={errors.name?.message}
        />
        <FormControlTextArea
          {...register('description')}
          id="description"
          label="Descripción"
          rows={5}
          labelAbove={true}
          errMsg={errors.description?.message}
        />
        <FormControlField
          {...register('date')}
          id="date"
          type="date"
          label="Fecha"
          labelAbove={true}
          errMsg={errors.date?.message}
        />
        <FormControlField
          {...register('time')}
          id="time"
          type="time"
          label="Hora"
          labelAbove={true}
          errMsg={errors.time?.message}
        />
        <FormControlField
          {...register('place')}
          id="place"
          label="Lugar"
          labelAbove={true}
          errMsg={errors.place?.message}
        />
        <FormControlTextArea
          {...register('terms')}
          id="terms"
          label="Términos"
          rows={10}
          labelAbove={true}
          errMsg={errors.terms?.message}
        />
        <FormControlField
          {...register('phone')}
          id="phone"
          label="Teléfono"
          labelAbove={true}
          errMsg={errors.phone?.message}
        />
        <FormControlField
          {...register('banner_image')}
          id="banner_image"
          label="Imagen del Banner (URL)"
          labelAbove={true}
          errMsg={errors.banner_image?.message}
        />
        <FormControlTextArea
          {...register('maps_link')}
          id="maps_link"
          label="Enlace de Google Maps"
          labelAbove={true}
          errMsg={errors.maps_link?.message}
        />
        <FormControlField
          {...register('online_service_charge')}
          id="online_service_charge"
          type="number"
          step="0.01"
          label="Service charge online"
          labelAbove={true}
          errMsg={errors.online_service_charge?.message}
        />
          <FormControlField
          {...register('manual_service_charge')}
          id="manual_service_charge"
          type="number"
          step="0.01"
          label="Service charge manual"
          labelAbove={true}
          errMsg={errors.manual_service_charge?.message}
        />
        <FormControlField
          {...register('min_age')}
          id="min_age"
          type="number"
          label="Edad Mínima"
          labelAbove={true}
          errMsg={errors.min_age?.message}
        />
        <FormControlField
          {...register('address')}
          id="address"
          label="Dirección"
          labelAbove={true}
          errMsg={errors.address?.message}
        />
        <h3>Metodos de pago</h3>
        <div className="mb-4">
          <Checkbox
            label="Recibe dinero de transferencias"
            labelClassName="text-gray-900"
            {...register('receives_wire_transfers')}
          />
        </div>
        <div className="mb-4">
          <Checkbox
            label="Acepta pagos online"
            labelClassName="text-gray-900"
            {...register('allow_online_payments')}
          />
        </div>
        <div className="mb-4">
          <Checkbox
            label="Acepta pagos manuales"
            labelClassName="text-gray-900"
            {...register('allow_manual_payments')}
          />
        </div>
        <div className="flex flex-col justify-between">
          <Button
            type="submit"
            primary
            className="mb-2"
            disabled={!isValid}
            loading={resCreateEvent.isLoading || resUpdateEvent.isLoading}
          >
            Guardar cambios
          </Button>
          <Button
            type="button"
            transparent
            onClick={() => navigate('..')}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EventEditor;
