import { Outlet } from 'react-router-dom';
import RequireAuth from '../store/utils/RequireAuth';

const AdminOutlet = () => (
  <RequireAuth>
    <Outlet />
  </RequireAuth>
);

export default AdminOutlet;
