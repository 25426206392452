import { useState, useEffect } from 'react';
import SortableTable from './SortableTable';
import TicketDTO from '../models/ticket';
import Counter from './Counter';
import { formatCurrency } from '../utils/shared';

export interface TicketSelection {
  ticket: TicketDTO;
  quantity: number;
}
interface Props {
  onTicketsChange: (ticketsSelection: TicketSelection[]) => void;
  tickets: TicketDTO[];
}

const SelectTicketsTable: React.FC<Props> = ({ onTicketsChange, tickets }) => {
  const [ticketsQuantity, setTicketsQuantity] = useState<TicketSelection[]>([]);
  const [sortedTickets, setSortedtickets] = useState<TicketDTO[]>([]);

  if (!tickets) {
    throw new Error('no tickets');
  }

  useEffect(() => {
    setSortedtickets([...tickets].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
  }, [tickets]);

  useEffect(() => {
    if (tickets) {
      setTicketsQuantity(tickets.map((t) => ({ ticket: t, quantity: 0 })));
    }
  }, [tickets]);

  useEffect(() => {
    onTicketsChange(ticketsQuantity.filter((t) => t.quantity > 0));
  }, [ticketsQuantity]);

  useEffect(() => {
    if (tickets) {
      setTicketsQuantity(tickets.map((t) => ({ ticket: t, quantity: 0 })));
    }
  }, [tickets]);

  const handleQuantityUpdate = (id: number, updatedQuantity: number) => {
    const copy = [...ticketsQuantity];
    const i = copy.findIndex((t) => t.ticket.id === id);
    if (i === -1) {
      return null;
    }
    copy[i] = { ...copy[i], quantity: updatedQuantity };
    return setTicketsQuantity(copy);
  };

  const config = [
    {
      label: 'Nombre',
      render: (ticket: TicketDTO) => (
        <div className=" flex flex-col align-items-center justify-center">
          <span>{ticket.name}</span>
          {ticket.total_amount - ticket.amount_taken < 10
            && ticket.total_amount - ticket.amount_taken > 0 && (
              <span className="text-xs text-gray-500">
                ¡Solo quedan {ticket.total_amount - ticket.amount_taken}!
              </span>
          )}
        </div>
      ),
    },
    {
      label: 'Precio',
      render: (ticket: TicketDTO) => (ticket.has_open_price ? '💰💰💰' : `${formatCurrency(ticket.price * ticket.items_create)}`),
    },
    {
      label: 'Cantidad',
      render: (ticket: TicketDTO) => (ticket.amount_taken < ticket.total_amount ? (
          <div className="flex justify-center align-items-center">
            <Counter
              key={ticket.id}
              upperLimit={Math.min(
                ticket.max_per_order,
                ticket.total_amount - ticket.amount_taken,
              )}
              onValueChange={(newValue: number) => handleQuantityUpdate(ticket.id, newValue)
              }
            />
          </div>
      ) : (
          <span className="flex justify-center align-items-center text-red-500">
            AGOTADAS
          </span>
      )),
    },
  ];

  const keyFn = (ticket: TicketDTO) => ticket.id;

  return (
    <SortableTable
      data={sortedTickets}
      config={config}
      keyFn={keyFn}
      pagination={false}
      search={false}
    />
  );
};

export default SelectTicketsTable;
