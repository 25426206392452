import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useState } from 'react';
import FormControlField from './FormControlField';
import Button from './Button';
import { useCreateUserMutation } from '../store';
import errorResponseToToast from '../utils/errorToastResponse';
import UserDTO from '../models/user';
import { toastError } from '../utils/toasts';
import errorResponseToString from '../utils/errorResponseToString';

interface Props {
  onSuccess: (user: UserDTO) => void;
}

interface FormData {
  name: string;
  email: string;
  emailConfirm: string;
  password: string;
  passwordConfirm: string;
}

const noSpaces = (value: string) => !/\s/.test(value);
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validationSchema = yup.object<FormData>().shape({
  name: yup
    .string()
    .required('Debes ingresar tu nombre'),
  email: yup
    .string()
    .required('Debes ingresar un correo')
    .matches(emailRegex, 'Este correo no está bien')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
  emailConfirm: yup
    .string()
    .required('Por seguridad, debes confirmar tu correo')
    .oneOf([yup.ref('email')], 'Los correos no coinciden')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
  password: yup
    .string()
    .required('Debes ingresar una contraseña')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
  passwordConfirm: yup
    .string()
    .required('Por seguridad, debes confirmar tu contraseña')
    .oneOf([yup.ref('password')], 'Los correos no coinciden')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
});

const SignUpForm: React.FC<Props> = ({ onSuccess }) => {
  const [createUser, results] = useCreateUserMutation();
  const [errorMsg, setErrorMsg] = useState('');
  const form = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    register, handleSubmit, formState,
  } = form;

  const handleSignUp = async () => {
    const { name: username, email, password } = form.getValues();
    try {
      const response = await createUser({ username, email, password });
      if ('error' in response) {
        setErrorMsg(errorResponseToString(response.error));
        return errorResponseToToast(response.error);
      }
      if (response.data) {
        return onSuccess(response.data);
      }
      throw new Error(`no response data. response is ${response}`);
    } catch (e) {
      console.error(e);
      return toastError('Ocurrió un error');
    }
  };

  return (
    <div className="admin px-4">
      <div className="flex flex-col items-center py-10">
        <div className="h-12 my-5">
          <img src="/logo59x61.png" />
        </div>
        <h1 className='text-2xl mb-5'>Crear usuario</h1>
        <p>Crea una cuenta en Avantti</p>
      </div>
      <form className='space-y-2' onSubmit={handleSubmit(handleSignUp)}>
        <FormControlField
          label="Nombre"
          labelAbove={true}
          id="name"
          {...register('name', {
            required: true,
          })}
          errMsg={formState.errors.name?.message}
        />
        <FormControlField
          label="Correo"
          labelAbove={true}
          id="email"
          {...register('email', {
            required: true,
          })}
          errMsg={formState.errors.email?.message}
        />
        <FormControlField
          label="Confirmar correo"
          labelAbove={true}
          id="emailConfirm"
          {...register('emailConfirm', {
            required: true,
          })}
          errMsg={formState.errors.emailConfirm?.message}
        />
        <FormControlField
          label="Contraseña"
          labelAbove={true}
          id="password"
          type='password'
          {...register('password', {
            required: true,
          })}
          errMsg={formState.errors.password?.message}
        />
        <FormControlField
          label="Confirmar contraseña"
          labelAbove={true}
          id="passwordConfirm"
          type='password'
          {...register('passwordConfirm', {
            required: true,
          })}
          errMsg={formState.errors.passwordConfirm?.message}
        />
        {errorMsg && <p className="text-red-500">{errorMsg}</p>}
        <div className="flex justify-center mt-10">
          <Button loading={results.isLoading} className='text-lg' disabled={!formState.isValid} type="submit" primary>
            Crear usuario
          </Button>
        </div>
      </form>
    </div>
  );
};
export default SignUpForm;
